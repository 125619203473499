import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import duxee from "assets/clients/duxee_office_logo_svg.svg";
import thebloomingshop from "assets/clients/thebloomingshop.jpg";

const Wrapper = styled(motion.div)`
  display: flex;
  background: #010101;
  border-bottom: 1px solid black;
  flex-direction: column;
  @media only screen and (max-device-width: 812px) {
    margin-top: 20%;
  }
  padding: 50px;
`;
const Header = styled(motion.h1)`
  font-family: "Montserrat";
  color: #ffffff;
  font-size: 4em;
  text-align: center;
  @media only screen and (max-device-width: 812px) {
    font-size: 3em;
  }
  margin-bottom: 5%;
`;
const HeaderOutline = styled.span`
  text-shadow: -1px -1px 0 #3beeb7, 1px -1px 0 #3beeb7, -1px 1px 0 #3beeb7,
    1px 1px 0 #3beeb7;
  color: #010101;
`;
const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  gap: 5%;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
const ClientImage = styled.img`
  width: 250px; /*set the width or max-width*/
  height: auto;
  background: white;
  object-fit: contain;
  aspect-ratio: 1;
  padding: 15px;
  background: white;
  border-radius: 10px;
  @media only screen and (max-device-width: 812px) {
    width: 250px; /*set the width or max-width*/
    height: auto;
    margin-top: 20px;
  }
`;
const ClientContainer = styled(motion.a)`
  cursor: pointer;
`;
let clients = [
  {
    logo: duxee,
    link: "https://www.duxeeoffice.com/",
  },
  {
    logo: thebloomingshop,
    link: "https://thebloomingshop.com/en/",
  },
];
const Client = ({ client, index }) => {
  return (
    <ClientContainer
      target="_blank"
      href={client.link}
      whileHover={{
        scale: 1.1,
      }}
      initial={{
        y: 100,
        opacity: 0,
      }}
      whileInView={{
        y: 0,
        opacity: 1,
        transition: {
          delay: 0.3 * index,
        },
      }}
    >
      <ClientImage src={client.logo} />
    </ClientContainer>
  );
};
const Clients = () => {
  return (
    <Wrapper id="clients">
      <Header
        initial={{
          y: -50,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
      >
        <HeaderOutline>They</HeaderOutline> trusted us
      </Header>
      <ClientsWrapper>
        {clients.map((i, index) => (
          <Client index={index} key={index} client={i} />
        ))}
      </ClientsWrapper>
    </Wrapper>
  );
};

export default Clients;
