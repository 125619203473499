import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { TfiNewWindow } from "react-icons/tfi";
import ServiceModal from "./ServiceModal";
import { useState } from "react";
const Wrapper = styled.div`
  display: flex;
  background: #010101;
  flex-direction: column;
  padding: 5% 50px;
`;
const Header = styled(motion.h1)`
  font-family: "Montserrat";
  color: #ffff;
  font-size: 4em;
  text-align: center;
  margin-bottom: 10%;
  @media only screen and (max-width: 480px) {
    font-size: 3em;
  }
`;
const HeaderOutline = styled.span`
  text-shadow: -1px -1px 0 #3beeb7, 1px -1px 0 #3beeb7, -1px 1px 0 #3beeb7,
    1px 1px 0 #3beeb7;
  color: #010101;
`;
const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  @media only screen and (max-device-width: 812px) {
    flex-direction: column;
  }
`;

const Card = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 100px;
  cursor: pointer;
  background: #121212;
  padding: 25px 30px;
  border-radius: 10px;
  transition: 0.3s;
  @media only screen and (max-device-width: 812px) {
    width: 280px;
    height: 170px;
    margin-bottom: 20px;
    padding: 10px 25px;
  }
  &:hover {
    background: #2a2a2a;
  }
`;
const CardHeader = styled.p`
  color: #ffffff;
  font-family: "Montserrat";
  letter-spacing: 5px;
  font-size: 25px;
`;
const CardMetaWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const CardIconWrapper = styled.div`
  font-size: 20px;
  margin-left: auto;
  color: #ffffff;
`;
const services = [
  {
    name: "Software development",
  },
  {
    name: "UX/UI",
  },
  {
    name: "Graphic Design & Marketing",
  },
];
let services_details = [
  {
    name: "Software Development",
    key: "software_development",
    list: [
      "E-commerce Solutions",
      "CRM Solutions",
      "CMS Solutions",

      "Software Development",
      "Website Development",
      "Mobile App Development",
      "Business intelligence",
    ],
  },
  {
    name: "UX UI Design",
    key: "ui_ux",
    list: [
      "User stories",
      "User flow charts, diagrams",
      "Wireframes",
      "Content strategy",
      "Information architecture",
      "Brand and design system development",
    ],
  },
  {
    name: "Graphic Design & Marketing",
    key: "graphic_design",
    list: [
      "Graphic Design",
      "Social media management",
      "Logo design",
      "Digital marketing strategy",
      "Branding",
    ],
  },
];
const ServiceCard = ({ data, handleClick, index }) => {
  return (
    <Card
      initial={{
        y: 100,
        opacity: 0,
      }}
      whileInView={{
        y: 0,
        opacity: 1,
        transition: {
          delay: 0.3 * index,
        },
      }}
      onClick={(e) => handleClick()}
    >
      <CardMetaWrapper>
        <CardHeader>{data.name}</CardHeader>
        <CardIconWrapper>
          <TfiNewWindow />
        </CardIconWrapper>
      </CardMetaWrapper>
    </Card>
  );
};
const ServicesBrochure = () => {
  const [serviceIndex, setServiceIndex] = useState(-1);
  return (
    <>
      <AnimatePresence>
        {serviceIndex >= 0 && (
          <ServiceModal
            data={services_details[serviceIndex]}
            closeModal={(e) => setServiceIndex(-1)}
          />
        )}
      </AnimatePresence>
      <Wrapper id="services">
        {" "}
        <Header>
          <HeaderOutline>OUR</HeaderOutline> SERVICES
        </Header>
        <CardsWrapper>
          {services.map((i, index) => (
            <ServiceCard
              handleClick={(e) => setServiceIndex(index)}
              key={index}
              index={index}
              data={i}
            />
          ))}
        </CardsWrapper>
      </Wrapper>
    </>
  );
};

export default ServicesBrochure;
