import styled from "styled-components";
import GlobalFonts from "./assets/fonts/fonts";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import Home from "pages/home/Home";
import Contact from "pages/contact/Contact";
import WebDevService from "pages/services/WebDevService";
import ServiceComponent from "components/service/ServiceComponent";
import Navbar from "components/headers/Navbar";
import ContactUs from "components/contact-us/ContactUs";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const AppWrapper = styled.div`
  min-height: 100vh;
  background: #010101;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const services = [
  {
    link: "/creation-site-web",
    data: {
      header: "Création site web",
      paragraph: [
        <b>
          Vous cherchez une agence de développement web fiable et de qualité?
        </b>,
        " Découvrez Nexters, l'agence spécialisée en création de sites web modernes, performants et adaptés à vos besoins. Chez Nexters, nous mettons l'accent sur la conception, l'expérience utilisateur, l'optimisation pour les moteurs de recherche et la satisfaction de nos clients. Nous travaillons en étroite collaboration avec vous pour créer des solutions personnalisées et efficaces pour votre entreprise. Contactez-nous dès maintenant pour discuter de votre projet web et bénéficiez de notre expertise et de notre engagement pour des résultats exceptionnels.",
      ],

      illustration: require("assets/illustrations/web-dev-illustration.jpg"),
      servicesList: {
        header: "Ce que nous pouvons développer pour vous",
        items: [
          "Site vitrine",
          "Site dynamique",
          "E-commerce",
          "Sur mesure",
          "Référencement",
          "Optimisation de site Web",
        ],
      },
    },
  },
];
function App() {
  const [isContactUsOpen, setContactUsOpen] = useState(false);
  const toggleContact = (e) => setContactUsOpen(!isContactUsOpen);

  return (
    <AppWrapper>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <GlobalFonts />
      <ContactUs isOpen={isContactUsOpen} toggleOpen={(e) => toggleContact()} />
      <Router>
        <Navbar toggleContact={(e) => toggleContact()} />
        {isContactUsOpen && <h1>HI</h1>}
        <Routes>
          <Route path="/" element={<Home toggleContact={toggleContact} />} />
          <Route path="/contact" element={<Contact />} />
          {services.map((i, index) => (
            <Route
              key={index}
              path={"/:id" + i.link}
              element={<ServiceComponent data={i.data} />}
            />
          ))}
          {/* <Route path="/creation-site-web" element={<WebDevService />} />
          <Route path="/conception-graphique" element={<Contact />} />
          <Route path="/marketing-digital" element={<Contact />} />
          <Route path="/ux-ui" element={<Contact />} /> */}
        </Routes>
      </Router>
    </AppWrapper>
  );
}

export default App;
