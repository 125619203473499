import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
const SvgRotate = styled(motion.svg)``;
const CurveText = ({ text }) => {
  return (
    <SvgRotate
      whileHover={{
        scale: 1.1,
        rotate: -360,
        transition: {
          duration: 1,
        },
      }}
      animate={{
        rotate: [-360, 360],
        transition: {
          repeat: Infinity,
          duration: 15,
        },
      }}
      viewBox="0 0 100 100"
      width="100"
      height="100"
    >
      <defs>
        <path
          id="circle"
          d="
          M 50, 50
          m -37, 0
          a 30,30 0 1,1 74,0
          a 30,30 0 1,1 -74,0"
        />
      </defs>
      <text
        style={{ userSelect: "none" }}
        fill="#3BEEB7"
        fontWeight="bold"
        fontSize="18"
      >
        <textPath
          style={{
            fontFamily: "Montserrat",
          }}
          href="#circle"
        >
          {text}
        </textPath>
      </text>
    </SvgRotate>
  );
};

export default CurveText;
