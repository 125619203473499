import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimationControls } from "framer-motion";
import NavItem from "./components/NavItem";
import logo_off from "assets/svgs/logo.svg";
import { GrClose } from "react-icons/gr";
import ScrollIntoView from "react-scroll-into-view";
import { Link } from "react-router-dom";

const Wrapper = styled.nav`
  display: flex;
  padding: 5px;
  position: fixed;
  align-items: center;
  // background: rgba(0, 0, 0, 0.25);
  // box-shadow: 0 8px 32px 0 rgba(59, 238, 183, 0.5);
  backdrop-filter: blur(18.5px);
  z-index: 99;
  -webkit-backdrop-filter: blur(18.5px);
  gap: 10px;
  width: 100%;
  background: #010101;
  @media only screen and (max-device-width: 812px) {
    overflow: hidden;
  }
`;
const PortfolioLink = styled.a`
  font-size: 30px;
  margin-left: auto;
  margin-right: 10%;
  transition: 0.3s;
  cursor: pointer;
  font-family: "Havana Regular";

  &:hover {
    text-decoration: underline;
  }
`;

const ItemsWrapper = styled(motion.div)`
  padding: 5px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 20%;
  gap: 10%;
  @media only screen and (max-device-width: 812px) {
    display: none;
  }
`;
const LogoWrapper = styled(motion.div)`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PhoneMenu = styled(motion.div)`
  display: none;
  @media only screen and (max-device-width: 812px) {
    display: block;
    align-items: center;
    margin-left: auto;
    font-size: 25px;
    margin-right: 10%;
    font-family: "Source Sans Pro";
    font-weight: 300;
    color: #63e0e4;
    text-shadow: -0.3px -0.3px 0 #010101, 0.3px -0.3px 0 #010101,
      -0.3px 0.3px 0 #010101, 0.3px 0.3px 0 #010101;
  }
`;
const PhoneNav = styled(motion.div)`
  display: none;
  @media only screen and (max-device-width: 812px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    z-index: 99;
    height: 100%;
    background: #010101;
  }
`;
const CloseButton = styled.button`
  margin-left: auto;
  padding: 20px;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 5%;
  font-size: 20px;
  display: flex;
  align-items: center;
  background: #3beeb7;
  cursor: pointer;
  color: white;
  border: none;
`;
const PhoneNavLinksWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;
const PhoenNavItem = styled(motion.div)`
  font-family: "Montserrat";
  font-size: 3em;
  margin: 5px;
  color: white;
`;
const Logo = styled.img`
  width: 140px;
  @media only screen and (max-device-width: 812px) {
    width: 110px;
  }
`;
const links = [
  { label: "Process", external: false, id: "process", page: "/" },
  { label: "Services", external: false, id: "services", page: "/" },
  {
    label: "Contact us",
    external: false,
    isContactUs: true,
    to: "contact",
    page: "/",
  },
];

const Navbar = ({ toggleContact }) => {
  const [isOpenPhoneNav, setOpenPhoneNav] = useState(false);
  const layoutAnimationControls = useAnimationControls();
  let startToggling = async () => {
    await layoutAnimationControls.start({
      y: -1000,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    });
    toggle();
  };
  const toggle = () => {
    setOpenPhoneNav(!isOpenPhoneNav);
  };
  useEffect(() => {
    if (isOpenPhoneNav) {
      layoutAnimationControls.start({
        y: 0,
        opacity: 1,

        transition: {
          duration: 0.5,
        },
      });
    }
  }, [isOpenPhoneNav]);
  return (
    <>
      <Wrapper>
        <ScrollIntoView selector="#home">
          <LogoWrapper>
            <Link to="/">
              <Logo src={logo_off} />
            </Link>
          </LogoWrapper>
        </ScrollIntoView>
        <ItemsWrapper layout>
          {links.map((i, index) => (
            <NavItem
              handleClick={(e) => {
                i.isContactUs && startToggling() && toggleContact();
                !i.isContactUs && startToggling();
              }}
              key={index}
              data={i}
              // animate={animateItemControls}
              // onHoverStart={() => {
              //   console.log("yo");
              //   setIsPlaying(i.label);

              //   if (
              //     isAnimationPlaying.find((j) => j.label === i.label).isPlaying
              //   ) {
              //     animateItem();
              //     console.log("yod");
              //   }
              // }}
              // onAnimationComplete={() => {
              //   setIsPlaying(i.label);
              // }}
            ></NavItem>
          ))}
        </ItemsWrapper>
        {/* <PortfolioLink to="/portfolio">
      <motion.p
        style={{
          textDecoration: "underline",
        }}
        initial={{
          color: "#8154EF",
          textDecorationColor: "#8154EF",
        }}
        animate={{
          color: ["#3BEEB7", "#8154EF", "#115BFA"],
          textDecorationColor: ["#3BEEB7", "#8154EF", "#115BFA"],
        }}
        transition={{
          repeat: Infinity,
          duration: 2,
        }}
      >
        Portfolio
      </motion.p>
    </PortfolioLink> */}
        <PhoneMenu onClick={(e) => toggle()}>Menu</PhoneMenu>
      </Wrapper>
      <PhoneNav
        initial={{
          y: 800,
          opacity: 0,
        }}
        animate={layoutAnimationControls}
      >
        <CloseButton
          onClick={(e) => {
            startToggling();
          }}
        >
          <GrClose />
        </CloseButton>
        <PhoneNavLinksWrapper>
          {links.map((i, index) => (
            <PhoenNavItem
              key={index}
              initial={{
                y: -50,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3 * index,
                },
              }}
              onClick={() => {
                i.isContactUs && startToggling() && toggleContact();
                !i.isContactUs && startToggling();
              }}

              // animate={animateItemControls}
              // onHoverStart={() => {
              //   console.log("yo");
              //   setIsPlaying(i.label);

              //   if (
              //     isAnimationPlaying.find((j) => j.label === i.label).isPlaying
              //   ) {
              //     animateItem();
              //     console.log("yod");
              //   }
              // }}
              // onAnimationComplete={() => {
              //   setIsPlaying(i.label);
              // }}
            >
              {!i.isContactUs ? (
                <ScrollIntoView selector={"#" + i.id}>{i.label}</ScrollIntoView>
              ) : (
                i.label
              )}
            </PhoenNavItem>
          ))}
        </PhoneNavLinksWrapper>
      </PhoneNav>
    </>
  );
};

export default Navbar;
