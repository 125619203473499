import { motion } from "framer-motion";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
// import OutlinedHeader from "components/OutlinedHeader/OutlinedHeader";
import { Scrollbars } from "react-custom-scrollbars-2";

const Wrapper = styled.div`
  background-color: rgba(18, 18, 18, 0.6);
  z-index: 10000;
  position: fixed;
  height: 100vh;
  width: 100%;
  inset: 0;
`;
const DataContainer = styled(motion.div)`
  position: absolute;
  height: 80%;
  background: #121212;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  left: 30%;
  top: 10%;
  width: 40%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    width: 90%;
    left: 0%;
  }
`;
const HeaderBackground = styled.div`
  background: ${(props) => props.background_color};
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 100px;
  display: flex;
`;

const CloseButtonWrapper = styled.div`
  padding: 10px;
  font-size: 25px;
  margin-left: auto;
  margin-right: 15px;
  color: white;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const Header = styled(motion.h1)`
  font-family: "Montserrat";
  color: #ffff;
  font-size: 2.5em;
  letter-spacing: 2px;
  @media only screen and (max-width: 480px) {
    font-size: 2em;
  }
`;
const HeaderOutline = styled.span`
  text-shadow: -1px -1px 0 #3beeb7, 1px -1px 0 #3beeb7, -1px 1px 0 #3beeb7,
    1px 1px 0 #3beeb7;
  color: #010101;
`;

const ServiceHeader = styled.p`
  color: #ffff;
  font-family: "Montserrat";
  font-size: 2.5em;
`;
const ListContainer = styled.div`
  display: flex;
  // flex-direction: column;
  max-height: 70%;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  line-height: 3em;
  @media only screen and (max-width: 480px) {
    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(41, 228, 16, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3beeb7;
      outline: 1px solid #010101;
      border-radius: 10px;
    }
    gap: 20px;
    padding: 0px;
    overflow-y: scroll;
    max-height: 100%;
  }
`;
const ListItem = styled(motion.div)`
  font-size: 1.75em;
  color: #ffff;
  font-family: "Montserrat";
  width: 45%;
  text-align: start;
  background: #3bf1b7;
  padding: 10px;
  color: #010101;
  border-radius: 5px;
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-right: 10px;
    font-size: 1.3em;
    &:last-child {
      margin-bottom: 25%;
    }
  }
`;
function ServiceModal({ data, background_color, closeModal, isOpen }) {
  const dataContainerRef = useRef(null);
  useLayoutEffect(() => {
    function handleClickOutside(event) {
      if (
        dataContainerRef.current &&
        !dataContainerRef.current.contains(event.target) &&
        isOpen
      ) {
        closeModal();
      }
    }
    setTimeout(() => {
      document.addEventListener("click", handleClickOutside);
    }, 500);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dataContainerRef]);
  return (
    <Wrapper>
      <DataContainer
        ref={dataContainerRef}
        initial={{
          y: 900,
          height: 300,
        }}
        exit={{
          height: 300,
          y: 900,
          transition: {
            y: {
              delay: 0.6,
            },
            height: {
              delay: 0.3,
            },
          },
        }}
        transition={{ delay: 0.3, height: { delay: 0.7 } }}
        animate={{
          y: 0,
          height: "95%",
        }}
      >
        <HeaderBackground background_color={background_color}>
          <CloseButtonWrapper onClick={(e) => closeModal()}>
            <AiOutlineClose />
          </CloseButtonWrapper>
        </HeaderBackground>

        {/* <Scrollbars
          style={{
            width: "100%",
            height: "100%",
          }}
        > */}
        <Header>
          <HeaderOutline>{data.name}</HeaderOutline>
        </Header>
        <ListContainer>
          {data.list.map((i, index) => (
            <ListItem
              initial={{
                y: 1000,
              }}
              exit={{
                y: 900,
                transition: {
                  y: {
                    delay: index / 9,
                  },
                },
              }}
              animate={{
                y: 0,
              }}
              transition={{ delay: index / 3 }}
            >
              {i}
            </ListItem>
          ))}
        </ListContainer>
        {/* </Scrollbars> */}
      </DataContainer>
    </Wrapper>
  );
}

export default ServiceModal;
