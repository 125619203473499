import * as Yup from "yup";

const contactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name field is empty."),
  projectType: Yup.string().required(
    "You need to provide us the project type."
  ),
  email: Yup.string()
    .email("Invalid email")
    .required("Email field is required."),
  phone: Yup.string().required("Phone field is required."),
  budget: Yup.string().required("Budget field is required."),
});
export { contactUsSchema };
