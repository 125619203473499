import Navbar from "components/headers/Navbar";
import React from "react";
import ServicesSection from "pages/home/sections/home/ServicesSection";
import OurProcess from "pages/home/sections/process/OurProcess";
import Services from "pages/home/sections/services/Services";
import styled from "styled-components";
import Clients from "./clients/Clients";
import ServicesBrochure from "./ServicesBrochure/ServicesBrochure";
const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Home = ({ toggleContact }) => {
  return (
    <HomeWrapper>
      <ServicesSection openContactUs={toggleContact} />
      <Clients />
      {/* <Services /> */}
      <ServicesBrochure />
      <OurProcess />
    </HomeWrapper>
  );
};

export default Home;
