import { createGlobalStyle } from "styled-components";

import HavanaRegular from "./havanaRegular.woff";
import Hyperwave from "./hyperwave.woff";
import RgItalic from "./RgItalic.woff";

export default createGlobalStyle`
    @font-face {
        font-family: 'Havana Regular';
        src: local('Havana Regular'), local('HavanaRegular'),
        url(${HavanaRegular}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Hyperwave';
        src: local('Hyperwave'), local('Hyperwave'),
        url(${Hyperwave}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Rg italic';
        src: local('Rg italic'), local('RgItalic'),
        url(${RgItalic}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
`;
