import ProgressBar from "components/ProgressBar/ProgressBar";
import { motion, useAnimationControls } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GrClose, GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import axios from "axios";
import FormStep from "./steps/FormStep";
import { useFormik } from "formik";
import { contactUsSchema } from "validation/contactusValidation";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  width: 35%;
  z-index: 999;
  position: fixed;
  height: 100%;
  @media only screen and (max-device-width: 812px) {
    width: 100%;
    position: fixed;
  }
`;
const Container = styled(motion.div)`
  display: flex;
  overflow: hidden;
  height: 100vh;
  width: 100%;
`;
const CloseButton = styled.button`
  margin-left: auto;
  padding: 20px;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 5%;
  font-size: 20px;
  display: flex;
  align-items: center;
  background: #3beeb7;
  cursor: pointer;
  color: white;
  border: none;
`;
const NextButton = styled.button`
  margin-left: auto;
  border-radius: 50%;

  padding: 20px;
  margin-right: 5%;
  &:hover {
    background: #3be5b7;
  }
  font-size: 20px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  background: #3beeb7;
  cursor: pointer;
  color: white;
  border: none;
`;
const SubmitButton = styled.button`
  margin-left: auto;
  border-radius: 3px;
  font-family: "Montserrat";
  padding: 20px;
  margin-right: 5%;
  &:hover {
    background: #3be5b7;
  }
  font-size: 20px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  background: #3beeb7;
  cursor: pointer;
  color: white;
  border: none;
`;
const BackButton = styled.button`
  padding: 20px;
  border-radius: 50%;

  margin-right: 5%;
  font-size: 20px;
  display: flex;
  align-items: center;
  background: #e7e7e7;
  transition: 0.3s;
  &:hover {
    background: #afafaf;
  }
  cursor: pointer;
  color: white;
  border: none;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  padding: 10px;
`;

let prices = {
  tnd: ["1000DT", "1000DT-2500DT", "2500DT-5000DT", "5000DT+"],
  euro: ["1000€-2000€", "2000€-5000€", "5000€+"],
};
const form_steps = [
  {
    label: "May I please have your name?",
    type: "text",
    inputs: ["name"],
    names: ["name"],
  },
  {
    label: "What kind of project would you like us to craft for you ?",
    type: "options",
    options: [
      "Web app design + Development",
      "Mobile app Design + Development",
      "Digital Marketing",
      "Graphic Design",
      "Branding",
      "All of the above",
      "Custom pack",
    ],
    names: ["projectType"],
  },
  {
    label: "What budget range do you have in mind? ",
    type: "options",
    options: prices.tnd,
    names: ["budget"],
  },
  {
    label: "Awesome, how can we reach you ?",
    type: "text",
    inputs: ["email", "phone"],
    names: ["email", "phone"],
  },
];
const FormsWrapper = styled.div`
  height: 70%;
  max-height: 70%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  min-height: 70%;
`;
const ContactUs = ({ isOpen, toggleOpen }) => {
  // const ref = useOutsideClick(toggleOpen);

  const layoutAnimationControls = useAnimationControls();
  const blurAnimationControls = useAnimationControls();
  const [honeyPot, setHoneyPot] = useState("");
  const [country, setCountry] = useState("");
  const [steps, setSteps] = useState(form_steps);
  const [currentStep, setCurrentStep] = useState(0);
  const [stopFlow, setStopFlow] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      budget: 0,
      projectType: 0,
      email: "",
      phone: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values) => {
      const { name, budget, projectType, email, phone } = values;
      let data = {
        name,
        budget: form_steps[2].options[budget],
        project: form_steps[1].options[projectType],
        email,
        phone,
        country: country,
      };
      console.log(country);
      if (honeyPot.length === 0) {
        try {
          await emailjs.send(
            "service_o64f10b",
            "template_fnd4757",
            data,
            "3cWh-By06NGC7vM1N"
          );
          toast.success("We will get back to you soon.");
        } catch (err) {
          toast.error("Something went wrong....");
        }
        StartToggling();
      }
    },
  });
  let fetchIpAndCountryInfos = async () => {
    try {
      let getIp = (await axios.get("https://geolocation-db.com/json/")).data[
        "IPv4"
      ];
      let getInfos = await (
        await axios.get(
          `http://ip-api.com/json/${getIp}?fields=status,message,continent,country,countryCode,region,regionName,city,zip,lat,lon,timezone,currency`
        )
      ).data;

      setCountry(getInfos.country);
      if (getInfos.continent === "Europe") {
        let shallowCopy = [...steps];
        let item = { ...shallowCopy[2] };
        item.options = prices.euro;
        shallowCopy[2] = item;
        setSteps(shallowCopy);
      }
    } catch (err) {}
  };
  useEffect(() => {
    fetchIpAndCountryInfos();
  }, []);
  useEffect(() => {
    console.log(country);
  }, [country]);
  useEffect(() => {
    if (isOpen) {
      layoutAnimationControls.start({
        x: 0,
        transition: {
          duration: 0.5,
        },
      });
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpen) {
      blurAnimationControls.start({
        x: 0,
        transition: {
          duration: 0.5,
        },
      });
    }
  }, [isOpen]);
  let StartToggling = async () => {
    blurAnimationControls.start({
      x: 800,
      transition: {
        duration: 0.5,
      },
    });
    await layoutAnimationControls.start({
      x: -800,
      transition: {
        duration: 0.5,
      },
    });
    toggleOpen();
  };
  useEffect(() => {
    let formik_error_names = Object.keys(formik.errors);
    let exists = steps[currentStep].names.some((r) =>
      formik_error_names.includes(r)
    );
    if (exists) {
      setStopFlow(true);
    } else {
      setStopFlow(false);
    }
  }, [formik.errors]);
  if (isOpen) {
    return (
      <Wrapper
        initial={{
          x: -800,
        }}
        exit={{
          x: -800,
          transition: {
            duration: 0.5,
          },
        }}
        animate={layoutAnimationControls}
      >
        <ProgressBar
          progressLevel={(currentStep / (form_steps.length - 1)) * 100}
        />
        {/* <button onClick={() => setProgressLevel(progressLevel + 10)}>
      Click
    </button> */}
        <CloseButton onClick={() => StartToggling()}>
          <GrClose />
        </CloseButton>

        <FormsWrapper>
          <input
            type="text"
            style={{ display: "none" }}
            value={honeyPot}
            onChange={(e) => setHoneyPot(e.target.value)}
          />

          {steps.map(
            (i, index) =>
              currentStep === index && (
                <FormStep key={index} formik={formik} formData={i} />
              )
          )}
        </FormsWrapper>
        <ButtonsWrapper>
          {currentStep > 0 && (
            <BackButton onClick={(e) => setCurrentStep(currentStep - 1)}>
              <GrLinkPrevious />
            </BackButton>
          )}

          {!stopFlow && currentStep < steps.length - 1 && (
            <NextButton onClick={(e) => setCurrentStep(currentStep + 1)}>
              <GrLinkNext />
            </NextButton>
          )}
          {currentStep === steps.length - 1 && (
            <SubmitButton
              onClick={(e) => {
                formik.submitForm();
              }}
            >
              Submit
            </SubmitButton>
          )}
        </ButtonsWrapper>
      </Wrapper>
    );
  }
};

export default ContactUs;
