import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import devIllustration from "assets/svgs/development.svg";
import graphicDesign from "assets/svgs/graphic_design.svg";
import uiUx from "assets/svgs/ux_ui.svg";
import illustrationContainer from "assets/svgs/illustrations_wrapper.svg";
import CurveText from "components/CurveText";
import hand from "assets/svgs/hand.svg";
import { isMobile } from "react-device-detect";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 5px;
  @media only screen and (max-device-width: 812px) {
    min-height: 95vh;
    width: 100%;
    padding: 0px;
  }
`;
const ContactUsButton = styled(motion.div)`
  margin-left: auto;
  cursor: pointer;
  @media only screen and (max-device-width: 812px) {
    position: absolute;
    bottom: 40px;
    left: 65%;
  }
`;

const Slogan = styled(motion.h1)`
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 60px;
  @media only screen and (max-device-width: 812px) {
    font-size: 40px;
    margin-top: -60px;
    margin-bottom: 80px;
  }
`;
const Service = styled(motion.h1)`
  color: #010101;
  font-family: "Rg italic";
  font-size: 60px;
  padding: 0;
  text-shadow: ${(props) =>
    `-1px -1px 0 ${props.color}, 1px -1px 0 ${props.color}, -1px 1px 0 ${props.color},
      1px 1px 0 ${props.color}`};

  min-width: 40%;
  max-width: 40%;
  margin: 0;
  @media only screen and (max-device-width: 812px) {
    position: absolute;
    top: 27%;
    width: 90%;
    left: 17%;
    font-size: 40px;
  }
`;
const SloganWrapper = styled(motion.div)`
  display: flex;
  gap: 10px;
  align-items: center;
  @media only screen and (max-device-width: 812px) {
    flex-direction: column;
    gap: 0px;
    width: 80%;
    align-items: flex-start;
    margin-left: 10%;
    margin-bottom: 25%;
  }
  justify-content: flex-end;
  width: 50%;
`;

const ServicesWrapper = styled.div`
  display: flex;

  width: 100%;
  @media only screen and (max-device-width: 812px) {
    flex-direction: column;
    justify-content: center;
    width: 80%;
  }
`;
const IllustrationWrapper = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  @media only screen and (max-device-width: 812px) {
    width: 100%;
  }
`;
const ContactAndScrollWrappers = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  top: 85%;
  width: 100%;
  @media only screen and (max-device-width: 812px) {
    top: 95%;
  }
`;
const ScrollToText = styled.div`
  color: white;
  font-family: "Montserrat", sans-serif;
  margin-left: auto;
  @media only screen and (max-device-width: 812px) {
    margin-left: 0px;
    text-align: center;
  }
`;
const Hand = styled.img`
  position: relative;
  right: 50%;
  margin-bottom: 30px;
`;
const IllustrationImageWrapper = styled.img`
  @media only screen and (max-device-width: 812px) {
    width: 90%;
  }
`;
const ServiceImageWrapper = styled.img`
  @media only screen and (max-device-width: 812px) {
    width: 50%;
  }
`;
const ServiceWrapper = styled(motion.div)`
  @media only screen and (max-device-width: 812px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
let services = [
  {
    illustration: devIllustration,
    color: "#28E1AC",
    label: "Software development",
  },
  { illustration: uiUx, color: "#E535FF", label: "UI/UX" },
  { illustration: graphicDesign, color: "#63E0E4", label: "Graphic design" },
];
const ServicesSection = ({ openContactUs }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    let changeWord = setTimeout(() => {
      setCurrentIndex(
        services.length - 1 > currentIndex ? currentIndex + 1 : 0
      );
    }, [3050]);

    return () => {
      clearTimeout(changeWord);
    };
  }, [currentIndex]);
  return (
    <Wrapper id="home">
      <ServicesWrapper>
        <SloganWrapper transition={{ duration: 0.3 }}>
          <Slogan
            animate={{
              y: [50, 0],
              opacity: [0, 1],
            }}
          >
            We work on
          </Slogan>
          {[
            services.map(
              (s) =>
                s.label === services[currentIndex].label && (
                  <Service
                    color={services[currentIndex].color}
                    key={s.label}
                    animate={{
                      opacity: [0, 1, 0],
                      y: [-50, 0, 50],
                      transition: {
                        duration: 3,
                        repeat: Infinity,
                      },
                    }}
                  >
                    {services[currentIndex].label}
                  </Service>
                )
            ),
          ]}
        </SloganWrapper>
        <IllustrationWrapper>
          <IllustrationImageWrapper
            alt="graphic"
            style={{ position: "absolute" }}
            src={illustrationContainer}
          />
          {services.map(
            (service) =>
              services[currentIndex].label === service.label && (
                <ServiceWrapper
                  key={service.label}
                  animate={{
                    opacity: [0, 1, 0],
                    y: [-50, 0, 50],
                    transition: {
                      duration: 3,
                      repeat: Infinity,
                    },
                  }}
                >
                  <ServiceImageWrapper
                    alt={services[currentIndex].label}
                    src={services[currentIndex].illustration}
                  />
                </ServiceWrapper>
              )
          )}
        </IllustrationWrapper>
      </ServicesWrapper>
      <ContactAndScrollWrappers>
        <ScrollToText>Scroll down for more details</ScrollToText>

        <ContactUsButton onClick={(e) => openContactUs()}>
          <CurveText text="Contact us - Contact us - " />
          <Hand alt="hand svg" src={hand} />
        </ContactUsButton>
      </ContactAndScrollWrappers>
    </Wrapper>
  );
};

export default ServicesSection;
