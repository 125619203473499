import { motion, useAnimationControls } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ScrollIntoView from "react-scroll-into-view";
import styled from "styled-components";
const Item = styled(motion.div)`
  color: white;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  font-family: "Montserrat", sans-serif;
  margin-right: 5px;
  font-weight: 300;
  width: 150px;
  text-transform: capitalize;
`;

const ItemAnchor = styled(motion.a)`
  color: inherit;
  text-decoration: none;
  user-select: none;
  display: flex;
`;
const ItemLink = styled(motion.div)`
  color: inherit;
  text-decoration: none;
  user-select: none;
  display: flex;
`;
const NavItem = ({ data, handleClick }) => {
  const animateItemControls = useAnimationControls();
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isAnimationPlaying === false) {
  //     setTimeout(() => {
  //       animateItemControls.start({
  //         color: "#FFFF",
  //       });
  //     }, 2000);
  //   }
  // }, [isAnimationPlaying]);

  let animateItem = () => {
    animateItemControls.start({
      color: ["#FFFF", "#FFFF", "#3BEEB7", "#3BEEB7", "#3BEEB7"],
      y: [0, -20, 20, 0],
      opacity: [1, 0, 0, 1],
      // display: ["block", "none", "none", "block"],
      transition: {
        duration: 0.2,
      },
    });
  };
  let deAnimateItem = () => {
    animateItemControls.start({
      color: ["#3BEEB7", "#3BEEB7", "#FFFF", "#FFFF", "#FFFF"],
      y: [0, 20, -20, 0],
      opacity: [1, 0, 0, 1],
      // display: ["block", "none", "none", "block"],
      transition: {
        duration: 0.2,
      },
    });
  };

  return data.isContactUs ? (
    <Item
      onMouseEnter={() => {
        if (!isAnimationPlaying) {
          setIsAnimationPlaying(true);
          animateItem();
        }
      }}
      onClick={(e) => handleClick()}
      // onHoverStart={() => {

      // }}
      onMouseLeave={() => {
        if (isAnimationPlaying) {
          setIsAnimationPlaying(false);
          deAnimateItem();
        }
      }}
    >
      {" "}
      <ItemAnchor animate={animateItemControls}>
        <ItemLink> {data.label}</ItemLink>
      </ItemAnchor>
    </Item>
  ) : (
    <Item
      onMouseEnter={() => {
        if (!isAnimationPlaying) {
          setIsAnimationPlaying(true);
          animateItem();
        }
      }}
      onClick={handleClick}
      // onHoverStart={() => {

      // }}
      onMouseLeave={() => {
        if (isAnimationPlaying) {
          setIsAnimationPlaying(false);
          deAnimateItem();
        }
      }}
    >
      {" "}
      <ItemAnchor animate={animateItemControls}>
        {" "}
        {!data.isContactUs ? (
          <ScrollIntoView
            onClick={(e) => {
              navigate(data.page);
            }}
            selector={"#" + data.id}
          >
            {data.label}
          </ScrollIntoView>
        ) : (
          data.label
        )}{" "}
      </ItemAnchor>
    </Item>
  );
};

export default NavItem;
