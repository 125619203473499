import React from "react";
import styled from "styled-components";
const StyledInput = styled.input`
  background: #dddddd;
  width: 80%;
  padding: 20px;
  font-size: 20px;
  border: 1px solid transparent;
  margin: 5px;

  &::placeholder {
    color: black;
  }
`;
const TextInput = ({ ...props }) => {
  return <StyledInput {...props} />;
};

export default TextInput;
