import axios from "axios";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: white;
  font-family: "Montserrat";
`;
const ContentWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //   background: #3beeb7;
`;
const Header = styled(motion.h1)`
  font-family: "Montserrat";
  font-size: 60px;
  text-shadow: -2px -1px 0 #3beeb7, 1px -1px 0 #3beeb7, -1px 1px 0 #3beeb7,
    1px 1px 0 #3beeb7;
  text-orientation: revert;
  color: #010101;
  font-weight: 300;
`;
const Img = styled(motion.img)`
  border-radius: 10px;
`;
const Paragraph = styled(motion.p)`
  font-family: "Montserrat";
  font-size: 17px;
  line-height: 1.9rem;

  width: 60%;
`;
const ServicesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  border-radius: 4px;
`;
const ServicesList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
const ServiceItem = styled(motion.div)`
  color: #010101;
  background: #3beeb7;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
`;
const ServicesHeader = styled.div`
  font-size: 25px;
  font-weight: 800;
  color: #3beeb7;
`;
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #3beeb7;
  width: 60%;
  background: #f5f5f5;
  color: #010101;
  padding: 10px;
`;
const FormInput = styled.input`
  background: #dddddd;
  width: ${(props) => (props.isFluid ? "94%" : "50%")};
  padding: 10px 15px;
  font-size: 20px;
  border: 1px solid transparent;
  margin: 5px;
  border-radius: 5px;

  &::focus {
    outline: 1px solid #3beeb7;
  }
  &::placeholder {
    color: black;
  }
`;
const FormTextarea = styled.textarea`
  background: #dddddd;
  width: ${(props) => (props.isFluid ? "94%" : "50%")};
  padding: 10px 15px;
  font-size: 20px;
  border: 1px solid transparent;
  margin: 5px;
  border-radius: 5px;

  &::focus {
    outline: 1px solid #3beeb7;
  }
  &::placeholder {
    color: black;
  }
`;
const Field = styled.div`
  display: flex;
`;
const ContactButton = styled(motion.button)`
  background: #3beeb7;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: bold;
  margin-top: 10px;
  font-size: 22px;
`;
const ServiceComponent = ({ data }) => {
  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     budget: 0,
  //     projectType: 0,
  //     email: "",
  //     phone: "",
  //   },
  //   onSubmit: async (values) => {
  //     console.log(values);

  //     const { name, budget, projectType, email, phone } = values;
  //     let data = {
  //       name,
  //       email,
  //       phone,
  //     };
  //     if (honeyPot.length === 0) {
  //       await axios.post(
  //         "https://formbold.com/s/3OYk6",
  //         {
  //           data,
  //         },
  //         { headers: { Accept: "application/json" } }
  //       );
  //       StartToggling();
  //     }
  //   },
  // });
  return (
    <Wrapper layout>
      <ContentWrapper>
        <IntroWrapper>
          <Header
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}
          >
            {data.header}
          </Header>

          <Img
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
              delay: 0.1,
            }}
            width="60%"
            src={data.illustration}
            alt="Service illustration"
          />

          <Paragraph
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            whileHover={{ scale: 1.1 }}
            transition={{
              hover: {
                delay: 0,
                duration: 0,
              },
              duration: 0.3,
              delay: 0.3,
            }}
          >
            {data.paragraph}
          </Paragraph>
        </IntroWrapper>
        <ServicesListWrapper>
          <ServicesHeader>{data.servicesList.header}</ServicesHeader>
          <ServicesList>
            {data.servicesList.items.map((j) => (
              <ServiceItem whileHover={{ scale: 1.1 }}>{j}</ServiceItem>
            ))}
          </ServicesList>
        </ServicesListWrapper>
        <FormWrapper>
          <h2>Demander un devis</h2>
          <Field>
            <FormInput isFluid={false} placeholder="Nom complet" />
            <FormInput isFluid={false} placeholder="Nom de l'entreprise" />
          </Field>
          <Field>
            <FormInput isFluid={false} placeholder="Numéro téléphone" />
            <FormInput isFluid={false} placeholder="Email" />
          </Field>

          <FormTextarea isFluid={true} placeholder="Détails sur le projet" />
          <ContactButton
            whileHover={{
              scale: 0.9,
            }}
          >
            Contactez Nous
          </ContactButton>
        </FormWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ServiceComponent;
