import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
const ServiceHeader = styled(motion.h1)`
  writing-mode: vertical-rl;
  @media only screen and (max-device-width: 812px) {
    writing-mode: horizontal-tb;
    font-size: 40px;
  }
  text-orientation: revert;
  font-family: "Montserrat";
  font-size: 60px;
  max-height: 300px;
  text-shadow: -1px -1px 0 #010101, 1px -1px 0 #010101, -1px 1px 0 #010101,
    1px 1px 0 #010101;
  color: #3beeb7;
`;

const Wrapper = styled(motion.div)`
  padding: 10px;
  display: flex;
  margin-top: 3%;
  @media only screen and (max-device-width: 812px) {
    flex-direction: column;
  }
`;
const ServiceDetailsWrapper = styled(motion.div)`
  background: white;
  border-radius: 15px;
  max-height: 300px;
  @media only screen and (max-device-width: 812px) {
    max-height: 100%;
    font-size: 30px;
  }
  width: 100%;
  font-family: "Montserrat";
  padding: 10px;
  font-size: 30px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
`;
const ListItem = styled(motion.div)`
  width: 40%;
  padding: 5px;
  @media only screen and (max-device-width: 812px) {
    width: 100%;
  }
`;
const ServiceDisplay = ({ dep }) => {
  return (
    <Wrapper layout>
      <ServiceHeader
        initial={{
          x: -70,
        }}
        whileInView={{
          x: 0,
        }}
      >
        {dep.dep_name}
      </ServiceHeader>
      <ServiceDetailsWrapper
        layout
        initial={{
          y: 70,
        }}
        whileInView={{
          y: 0,
        }}
      >
        {dep.list.map((i, index) => (
          <ListItem
            initial={{
              x: -80,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
          >
            -{i}
          </ListItem>
        ))}
      </ServiceDetailsWrapper>
    </Wrapper>
  );
};

export default ServiceDisplay;
