import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import ServicesCircularBar from "./ServicesCircularBar";
import ServiceDisplay from "./ServiceDisplay";

const Wrapper = styled(motion.div)`
  display: flex;
  background: #3beeb7;
  flex-direction: column;
  @media only screen and (max-device-width: 812px) {
    margin-top: 20%;
  }
  padding: 50px;
`;
const Header = styled(motion.h1)`
  font-family: "Montserrat";
  color: #010101;
  font-size: 4em;
  text-align: center;
  margin-bottom: 5%;
`;
const HeaderOutline = styled.span`
  text-shadow: -1px -1px 0 #010101, 1px -1px 0 #010101, -1px 1px 0 #010101,
    1px 1px 0 #010101;
  color: #3beeb7;
`;
let deps = [
  {
    dep_name: "Graphic Design & Marketing",
    key: "graphic_design",
    list: [
      "Graphic Design.",
      "Community management.",
      "Social media management.",
      "Logo design.",
      "Digital marketing strategy.",
      "Copywriting.",
    ],
  },
  {
    dep_name: "Software Development",
    key: "software_development",
    list: [
      "Web Development",
      "Mobile App Development",
      "Business Intelligence",
      "Refactoring",
      "Custom Software",
      "MVP",
      "DevOps",
    ],
  },
  {
    dep_name: "UX UI Design",
    key: "ui_ux",
    list: [
      "User stories.",
      "User flow charts, diagrams.",
      "Wireframes.",
      "Content strategy.",
      "Information architecture.",
      "Brand and design system development.",
    ],
  },
];
const Services = () => {
  const [getCurrentDep, setCurrentDep] = useState(0);

  return (
    <Wrapper id="services">
      <Header
        initial={{
          y: -50,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
      >
        <HeaderOutline>OUR</HeaderOutline> SERVICES
      </Header>
      <ServicesCircularBar
        current={getCurrentDep}
        changeDep={setCurrentDep}
        deps={deps}
      />
      {/* <ServicesWrapper layout>
        {deps.map((i) => (
          <Card data={i} key={i.key} />
        ))}
      </ServicesWrapper> */}
      <ServiceDisplay dep={deps[getCurrentDep]} />
    </Wrapper>
  );
};

export default Services;
