import React from "react";
import styled from "styled-components";
import { BsCircleFill, BsCircle } from "react-icons/bs";
import { motion } from "framer-motion";
const CheckBoxWrapper = styled(motion.div)`
  display: flex;
  width: 90%;
  font-family: "Montserrat", sans-serif;
  &:hover {
    background: #dddddd;
  }
  cursor: pointer;
  transition: 0.3s;
  padding: 20px 10px;
  font-size: 2rem;
  border: 1px solid black;
  ${(props) => props.is_checked && "background:#010101;"}
  ${(props) => props.is_checked && "color:#3beeb7;"}

  align-items: center;
  margin: 10px 5px;
`;
const IconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const CheckBox = ({ text, is_checked, onClick, curr }) => {
  return (
    <CheckBoxWrapper
      initial={{
        y: 100,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.3 * curr,
        },
      }}
      is_checked={is_checked}
      onClick={onClick}
    >
      {text}
      <IconWrapper>{is_checked ? <BsCircleFill /> : <BsCircle />} </IconWrapper>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
