import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10%;
`;
const ProcessesWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-device-width: 812px) {
    flex-wrap: wrap;
  }
  gap: 10px;
  padding: 10px;
  margin: 10px;
`;
const Header = styled(motion.h1)`
  font-family: "Montserrat";
  color: #ffff;
  font-size: 4em;
  text-align: center;
  margin-bottom: 5%;
`;
const HeaderOutline = styled.span`
  text-shadow: -1px -1px 0 #3beeb7, 1px -1px 0 #3beeb7, -1px 1px 0 #3beeb7,
    1px 1px 0 #3beeb7;
  color: #010101;
`;

const ProcessCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border: 1px solid #3c3c3c;
  background: #010101;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 300px;
  width: 300px;
`;
const PorcessNumber = styled(motion.div)`
  color: #3beeb7;
  font-size: 45px;
  font-family: "Montserrat";
`;
const ProcessLabel = styled(motion.h3)`
  font-family: "Montserrat";
  color: #afafaf;
  font-size: 22px;
  text-align: center;
`;

const processes = [
  {
    name: "Discovery",
  },
  {
    name: "Design",
  },
  {
    name: "Develop",
  },
  {
    name: "Feedback & Revisions",
  },
  {
    name: "Deliver",
  },
];
const IconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  color: #3beeb7;
`;
const OurProcess = () => {
  return (
    <Wrapper id="process">
      <Header>
        <HeaderOutline>OUR</HeaderOutline> PROCESS
      </Header>
      <ProcessesWrapper>
        {processes.map((process, index) => (
          <>
            {" "}
            <ProcessCard
              key={index}
              initial={{
                y: -50,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3 * index,
                },
              }}
            >
              <PorcessNumber>{index + 1}</PorcessNumber>
              <ProcessLabel> {process.name}</ProcessLabel>
            </ProcessCard>
          </>
        ))}
      </ProcessesWrapper>
    </Wrapper>
  );
};

export default OurProcess;
