import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import CheckBox from "./components/CheckBox";
import TextInput from "./components/TextInput";
import { Scrollbars } from "react-custom-scrollbars-2";

const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 95%;
  padding: 10px;
`;

const Header = styled(motion.p)`
  color: #010101;
  font-size: 27px;
  font-weight: 600;
  z-index: 150;
  -webkit-filter: blur(0);
  font-family: "Montserrat", sans-serif;
`;
const Paragraph = styled.p`
  color: #010101;
  font-family: "Montserrat", sans-serif;
  z-index: 150;
  -webkit-filter: blur(0);
`;
const ErrorMessage = styled.div`
  background: #ed4245;
  padding: 10px;
  margin: 5px;
  border-radius: 3px;
  color: white;
  font-family: "Montserrat", sans-serif;
  width: 83%;
  font-size: 20px;
`;
const FormStep = ({ formData, formik }) => {
  const [checkIndex, setCheckIndex] = useState(0);
  useEffect(() => {
    if (formData.type == "options")
      setCheckIndex(formik.values[formData.names[0]]);
  }, [formData]);
  let onCheck = (index, name) => {
    console.log(index);
    setCheckIndex(index);
    formik.setFieldValue(name, index - 1);
  };

  return (
    <Wrapper
      initial={{
        x: -200,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      }}
    >
      <Header
        initial={{
          y: 50,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
          },
        }}
      >
        {formData.label}
      </Header>
      {formData.names.map(
        (i, index) =>
          formik.touched[i] &&
          formik.errors[i] && (
            <ErrorMessage key={index}>{formik.errors[i]}</ErrorMessage>
          )
      )}
      {formData.type === "options" && (
        <Scrollbars
          style={{
            width: "100%",
            height: "400px",
          }}
        >
          {formData?.options.map((i, index) => (
            <CheckBox
              key={i}
              curr={index}
              is_checked={checkIndex === index + 1}
              onClick={(e) => onCheck(index + 1, formData.names[0])}
              text={i}
            />
          ))}
        </Scrollbars>
      )}
      {formData.type === "text" &&
        formData?.inputs.map((i, index) => (
          <TextInput
            autoFocus={index == 0}
            key={i}
            onBlur={formik.handleBlur}
            placeholder={i.charAt(0).toUpperCase() + i.slice(1, i.length)}
            name={formData.names[index]}
            onChange={formik.handleChange}
            value={formik.values[formData.names[index]]}
          />
        ))}
    </Wrapper>
  );
};

export default FormStep;
