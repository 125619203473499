import { motion } from "framer-motion";
import React, { useEffect } from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-left: 3%;
  @media only screen and (max-device-width: 812px) {
    flex-wrap: wrap;
  }
`;

const NavItem = styled(motion.button)`
  font-family: "Montserrat";
  background: #010101;
  font-size: 25px;
  border-radius: 5px;
  border: none;
  @media only screen and (max-device-width: 812px) {
    width: 100%;
  }
  padding: 10px;
  color: white;
  cursor: pointer;
`;
const ServicesCircularBar = ({ deps, changeDep, current }) => {
  return (
    <Wrapper layout>
      {deps?.length > 0 &&
        deps?.map((dep, index) => (
          <NavItem
            initial={{ background: "#010101", y: 20, opacity: 0 }}
            onClick={(e) => changeDep(index)}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.1 * index,
              },
            }}
            isCurrent={current === index}
            whileHover={{
              scale: 1.1,
              marginLeft: 20,
              marginRight: 20,
              background: "#3BEEB7",
              color: "#010101",
            }}
          >
            {dep.dep_name}
          </NavItem>
        ))}
    </Wrapper>
  );
};

export default ServicesCircularBar;
