import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
const Wrapper = styled(motion.div)`
  width: 100%;
`;
const ProgressIndicator = styled(motion.div)`
  background: #3beeb7;
  width: ${(props) => props.progressLevel}%;
  height: 15px;
`;
const ProgressBar = ({ progressLevel = 0 }) => {
  return (
    <Wrapper layout>
      <ProgressIndicator progressLevel={progressLevel} layout />
    </Wrapper>
  );
};

export default ProgressBar;
